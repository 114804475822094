<template>
  <div class="filesystem-file-uploader dropzone">
    <div class="dz-message">
      <slot>
        <p class="dz-message-idle">Subir archivos</p>
        <p class="dz-message-hover">Sueltalos aquí</p>
      </slot>
    </div>
  </div>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi';
import Dropzone from 'dropzone';

export default {
  name: 'dropzone',
  mixins: [useApi], // Necesario para obtener this.$httpClient
  props: {
    path: {
      type: String,
      required: true
    },

    maxFiles: {
      type: [String, Number],
      required: false,
      default: null
    },

    acceptedFiles: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      dropzone: null
    };
  },

  computed: {
    fullUrl() {
      return this.$httpClient.host + '/' + this.path;
    },

    headers() {
      return {
        Authorization: 'Bearer ' + this.$httpClient.token
      };
    }
  },

  methods: {
    clear() {
      this.dropzone.removeAllFiles();
    },

    open() {
      this.dropzone.hiddenFileInput.click();
    }
  },

  mounted() {
    this.dropzone = new Dropzone(this.$el, {
      url: this.fullUrl,
      headers: this.headers,
      autoProcessQueue: true,
      maxFiles: this.maxFiles,
      acceptedFiles: this.acceptedFiles,
      timeout: 1200000, //20min
      maxFilesize: 512,
      maxThumbnailFilesize: 512
    });

    this.dropzone.on('queuecomplete', () => {
      this.$emit('queuecomplete');
    });

    this.dropzone.on('success', (file, response) => {
      this.$emit('success', response);
    });

    this.dropzone.on('complete', file => {
      this.dropzone.removeFile(file);
      this.$emit('complete', file);
    });

    this.dropzone.on('error', (file, errorMessage, xhr) => {
      console.log(errorMessage);
      this.dropzone.removeFile(file);
      this.$emit('error', errorMessage);
    });

    this.dropzone.on('sending', (file, xhr, formData) => {
      xhr.ontimeout = error => {
        console.log(xhr, error);
        this.dropzone.removeFile(file);
        this.$emit('error', 'xhr timeout');
      };
    });
  }
};
</script>

<style lang="scss">
@import '~dropzone/dist/dropzone.css';

.filesystem-file-uploader {
  // Override default styles
  &.dropzone {
    background: transparent;
    padding: 0;
    border: 0;
    min-height: 24px;

    .dz-message {
      margin: 0;

      .dz-message-idle {
        display: block;
      }

      .dz-message-hover {
        display: none;
      }
    }
  }

  &.dz-drag-hover {
    border: 3px dashed #ccc;

    .dz-message-idle {
      display: none;
    }

    .dz-message-hover {
      display: block;
    }
  }
}
</style>